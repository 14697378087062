<template>
  <div class="ShadeBox" @mousewheel="mousewheel">
    <div class="maskLayer"></div>
    <div class="betaToast intro-x">
      <div class="toastTitle">提示</div>
      <div class="formDataClass">
        <div class="formNameclass">
          <p class="p1">是否退出登录?</p>
        </div>
      </div>
      <div class="buttom-p">
        <span class="span1" @click="setLogout">取消</span>
        <span class="span2" @click="getExit">确认</span>
      </div>
    </div>
  </div>
</template>


<script>
import request  from '../utils/request'


export default {
  data() {
    return {
     
    };
  },

  methods: {
    // 确认退出登录
    getExit: function () {
       this.$emit("getExit"); // 控制退出方法
    },

    // 取消
    setLogout: function () {
      this.$emit("setLogout"); // 控制弹窗关闭
    },

    //  遮罩层防滚动穿透
    mousewheel: function (e) {
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
.ShadeBox {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  // background: red;
  .maskLayer {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #000000;
    opacity: 0.7;
    width: 100%;
    height: 100%;

  }
  .betaToast {
    width: 20vw;
    min-width: 18rem;
    height: 200px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 22px;
    position: fixed;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    overflow: hidden;
    .toastTitle {
      border-bottom: 1px solid #f4f4f4;
      padding: 15px;
      font-size: 16px;
      color: #333333;
      box-sizing: border-box;
    }
  }
}

.formDataClass {
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
  .formNameclass {
    width: 100%;
    box-sizing: border-box;
    .p1 {
      height: 38px;
      line-height: 70px;
      font-size: 14px;
      color: #333333;
      text-align: center;
    }
  }
}

.buttom-p {
  width: 100%;
  border-top: 1px solid #f4f4f4;
  margin-top: 30px;
  text-align: right;
  padding: 20px;
  box-sizing: border-box;

  span {
    display: inline-block;
    padding: 10px 25px;
    margin-left: 4px;
    vertical-align: middle;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .span1 {
    border: 1px solid #e2e8f0;
    cursor:pointer;
  }

  .span2 {
    color: #fff;
    background: #1890ff;
    text-align: center;
    width: 100px;
    cursor:pointer;
  }

  .span2:active {
    background: #2d9aff;
  }

  .span1:active {
    background: #e9e9e9;
  }
}
</style>