var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('keep-alive',[_c(_vm.tag,{directives:[{name:"tippy",rawName:"v-tippy",value:({
      arrow: true,
      arrowType: 'round',
      boundary: 'viewport',
      placement: 'left',
      animateFill: false,
      animation: 'shift-away',
    }),expression:"{\n      arrow: true,\n      arrowType: 'round',\n      boundary: 'viewport',\n      placement: 'left',\n      animateFill: false,\n      animation: 'shift-away',\n    }"}],ref:"tooltip",tag:"component"},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }