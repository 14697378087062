<template>
  <!-- BEGIN: Top Bar -->
  <div
    v-if="userInfo"
    class="top-bar p-5"
    style="
      height: 80px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      z-index: 99;
      position: relative;
    "
  >
    <!-- BEGIN: Breadcrumb -->
    <div
      class="-intro-x breadcrumb mr-auto hidden sm:flex"
      style="float: left; margin-top: 10px"
    >
      <div>智莺生活</div>
      <ChevronRightIcon class="breadcrumb__icon" />

      <router-link class="breadcrumb--active" :to="roterLink(title[0])">{{
        title[0]
      }}</router-link>

      <ChevronRightIcon v-if="title[1]" class="breadcrumb__icon" />
      <div class="breadcrumb--active">{{ title[1] }}</div>
    </div>

    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8 relative" style="float: right;margin-top:4px">
      <div
        class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in"

      >
        <img alt="" :src="userInfo.avatar || 'http://osssys.izhyin.cn/9_avatar.png?1610590122'" />
      </div>

      <div v-show="isUserName" class="dropdown-boxs dropdown-box w-56">
        <div class="dropdown-box__content box bg-theme-38 text-white">
          <div class="p-4 border-b border-theme-40">
            <div class="font-medium">{{ userInfo.nickname || "会员昵称" }}</div>
            <div class="text-xs text-theme-41">会员ID：{{ userInfo.uid }}</div>
          </div>
          <div class="p-2">
            <p
              @click="getPersonal"
              href
              class=" personal flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1:bg-dark-3 rounded-md"

            >
              <UserIcon class="w-4 h-4 mr-2" /> 个人资料
            </p>
            <!-- <a
              href
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1:bg-dark-3 rounded-md"
            >
              <LockIcon class="w-4 h-4 mr-2" />修改密码
            </a>
            <a
              href
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1:bg-dark-3 rounded-md"
            >
              <HelpCircleIcon class="w-4 h-4 mr-2" />帮助
            </a> -->
          </div>
          <div class="p-2 border-t border-theme-40">
            <span
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1:bg-dark-3 rounded-md CursorPointer"
              @click="openLogout"
            >
              <ToggleRightIcon class="w-4 h-4 mr-2" />
              退出登录
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- END: Search -->
    <!-- BEGIN: Notifications -->
    <div
      class="intro-x dropdown relative mr-auto sm:mr-6"
      style="float: right; margin: 16px 23px 0 0"
    >
      <div
        class="dropdown-toggle notification cursor-pointer"
        :class="{ 'notification--bullet': false }"
      >
        <BellIcon class="notification__icon" style="margin-top: -3px" />
      </div>
      <div class="notification-content pt-2 dropdown-box">
        <div class="notification-content__box dropdown-box__content box">
          <div class="notification-content__title">系统通知</div>

          <div v-if="false">
            <div
              v-for="(faker, fakerKey) in $_.take($f(), 5)"
              :key="fakerKey"
              class="cursor-pointer relative flex items-center"
              :class="{ 'mt-5': fakerKey }"
            >
              <div class="w-12 h-12 flex-none image-fit mr-1">
                <img
                  alt=""
                  class="rounded-full"
                  :src="require(`@/assets/images/${faker.photos[0]}`)"
                />
                <div
                  class="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"
                ></div>
              </div>
              <div class="ml-2 overflow-hidden">
                <div class="flex items-center">
                  <a href="javascript:;" class="font-medium truncate mr-5">
                    {{ faker.users[0].name }}
                  </a>
                  <div class="text-xs text-gray-500 ml-auto whitespace-no-wrap">
                    {{ faker.times[0] }}
                  </div>
                </div>
                <div class="w-full truncate text-gray-600">
                  {{ faker.news[0].short_content }}
                </div>
              </div>
            </div>
          </div>

          <div v-else class="tongzhi-img">
            <img src="../assets/images/kong-tongzhi.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- END: Breadcrumb -->
    <!-- BEGIN: Search -->
    <div class="intro-x relative mr-3 sm:mr-6" style="float: right">
      <!-- <div class="search hidden sm:block">
        <input
          type="text"
          class="search__input input placeholder-theme-13"
          placeholder="搜索..."
          @focus="showSearchDropdown"
          @blur="hideSearchDropdown"
        />
        <SearchIcon class="search__icon" />
      </div> -->
      <a class="notification sm:hidden" href>
        <SearchIcon class="notification__icon" style="margin-top: 8px" />
      </a>
      <div class="search-result" :class="{ show: searchDropdown }">
        <div class="search-result__content">
          <div class="search-result__content__title">Pages</div>
          <div class="mb-5">
            <a href class="flex items-center">
              <div
                class="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"
              >
                <InboxIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Mail Settings</div>
            </a>
            <a href class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"
              >
                <UsersIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Users & Permissions</div>
            </a>
            <a href class="flex items-center mt-2">
              <div
                class="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"
              >
                <CreditCardIcon class="w-4 h-4" />
              </div>
              <div class="ml-3">Transactions Report</div>
            </a>
          </div>
          <div class="search-result__content__title">Users</div>
          <div class="mb-5">
            <a
              v-for="(faker, fakerKey) in $_.take($f(), 4)"
              :key="fakerKey"
              href
              class="flex items-center mt-2"
            >
              <div class="w-8 h-8 image-fit">
                <img
                  alt=""
                  class="rounded-full"
                  :src="require(`@/assets/images/${faker.photos[0]}`)"
                />
              </div>
              <div class="ml-3">{{ faker.users[0].name }}</div>
              <div
                class="ml-auto w-48 truncate text-gray-600 text-xs text-right"
              >
                {{ faker.users[0].email }}
              </div>
            </a>
          </div>
          <div class="search-result__content__title">Products</div>
          <a
            v-for="(faker, fakerKey) in $_.take($f(), 4)"
            :key="fakerKey"
            href
            class="flex items-center mt-2"
          >
            <div class="w-8 h-8 image-fit">
              <img
                alt=""
                class="rounded-full"
                :src="require(`@/assets/images/${faker.images[0]}`)"
              />
            </div>
            <div class="ml-3">{{ faker.products[0].name }}</div>
            <div class="ml-auto w-48 truncate text-gray-600 text-xs text-right">
              {{ faker.products[0].category }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- 退出登录弹窗 -->
    <logout-pop v-if="islogout" @setLogout="setLogout" @getExit="getExit"></logout-pop>
    <!-- END: Account Menu -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import request  from '../utils/request'
import Cookies from "js-cookie";
import logoutPop from '../modules/logoutPop'



export default {
  components: { logoutPop },
  data() {
    return {
      searchDropdown: false,
      isUserName: true, // 控制显示
      // 弹出显示
      islogout:false,
      userInfo: null,
    };
  },
  computed: {

    title: function () {
      if (typeof this.$route.meta.title == "object") {
        return this.$route.meta.title;
      } else {
        return new Array(this.$route.meta.title);
      }
    },

    roterLink: function () {
      return function (value) {
        return this.$store.state.sideMenu.menu.filter((item) => {
          return item.title == value;
        })[0].url;
      };
    },
  },


  created() {
    this.initUser()
  },

  methods: {

   initUser: function () {
      setTimeout(() => {
        if (Cookies.get("userInfo")) {
          this.userInfo = JSON.parse(Cookies.get("userInfo"));


          return;
        } else {
          this.initUser();
        }
      }, 800);
    },

    // 打开退出登录
    openLogout(){
       this.islogout = true
    },
    // 取消退出登录
    setLogout(){
        this.islogout = false
    },
    // 跳转至个人资料
    getPersonal(){
      // this.$router.push("/Dashboard/profile")
      // console.log(111)
        this.$router.push({
            name:'profile',
        });
    },

    showSearchDropdown() {
      this.searchDropdown = true;
    },
    hideSearchDropdown() {
      this.searchDropdown = false;
    },

    // 退出登录
    getExit: function () {
      this.islogout = false
      this.isUserName = false;
      request({url:"/api/sign/out",method:'POST'}).then((res) => {
        this.$router.push("/login");
      });
    },
  },
};
</script>



<style scoped lang="scss" >
.dropdown-boxs {
  z-index: 9999;
  right: 10px;
  position: absolute;
}
.personal{
  cursor: pointer;
}

.tongzhi-img {
  width: 100%;
  height: 200px;
  img {
    width: 150px;
    display: block;
    margin: 50px auto;
  }
}
</style>
