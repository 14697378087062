var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MobileMenu'),_c('div',{staticClass:"flex"},[_c('nav',{staticClass:"side-nav"},[_c('router-link',{staticClass:"intro-x flex items-center pl-5 pt-4",attrs:{"to":"/","tag":"a"}},[_c('span',{staticClass:"hidden xl:block text-white text-lg ml-3"},[_c('span',{staticClass:"font-medium"},[_vm._v("聚合联盟")])])]),_c('div',{staticClass:"side-nav__devider my-6"}),_c('ul',[_vm._l((_vm.formattedMenu),function(menu,menuKey){return [(menu == 'devider')?_c('li',{key:menuKey,staticClass:"side-nav__devider my-6"}):_c('li',{key:menuKey},[_c('SideMenuTooltip',{staticClass:"side-menu",class:{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown,
                },attrs:{"tag":"a","content":menu.title,"href":"javascript:;"},nativeOn:{"click":function($event){return _vm.linkTo(menu)}}},[_c('div',{staticClass:"side-menu__icon"},[_c(menu.icon,{tag:"component"})],1),_c('div',{staticClass:"side-menu__title"},[_vm._v(" "+_vm._s(menu.title)+" "),(_vm.$h.isset(menu.subMenu))?_c('ChevronDownIcon',{staticClass:"side-menu__sub-icon",class:{ 'transform rotate-180': menu.activeDropdown }}):_vm._e()],1)]),_c('transition',{on:{"enter":_vm.enter,"leave":_vm.leave}},[(_vm.$h.isset(menu.subMenu) && menu.activeDropdown)?_c('ul',_vm._l((menu.subMenu),function(subMenu,subMenuKey){return _c('li',{key:subMenuKey},[_c('SideMenuTooltip',{staticClass:"side-menu",class:{ 'side-menu--active': subMenu.active },attrs:{"tag":"a","content":subMenu.title,"href":"javascript:;"},nativeOn:{"click":function($event){return _vm.linkTo(subMenu)}}},[_c('div',{staticClass:"side-menu__icon"},[_c('ActivityIcon')],1),_c('div',{staticClass:"side-menu__title"},[_vm._v(" "+_vm._s(subMenu.title)+" "),(_vm.$h.isset(subMenu.subMenu))?_c('ChevronDownIcon',{staticClass:"side-menu__sub-icon",class:{
                            'transform rotate-180': subMenu.activeDropdown,
                          }}):_vm._e()],1)]),_c('transition',{on:{"enter":_vm.enter,"leave":_vm.leave}},[(
                          _vm.$h.isset(subMenu.subMenu) && subMenu.activeDropdown
                        )?_c('ul',_vm._l((subMenu.subMenu),function(lastSubMenu,lastSubMenuKey){return _c('li',{key:lastSubMenuKey},[_c('SideMenuTooltip',{staticClass:"side-menu",class:{ 'side-menu--active': lastSubMenu.active },attrs:{"tag":"a","content":lastSubMenu.title,"href":"javascript:;"},nativeOn:{"click":function($event){return _vm.linkTo(lastSubMenu)}}},[_c('div',{staticClass:"side-menu__icon"},[_c('ZapIcon')],1),_c('div',{staticClass:"side-menu__title"},[_vm._v(" "+_vm._s(lastSubMenu.title)+" ")])])],1)}),0):_vm._e()])],1)}),0):_vm._e()])],1)]})],2)],1),_c('div',{staticClass:"content"},[_c('TopBar'),_c('keep-alive',[_c('router-view')],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }